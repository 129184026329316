<template>
  <!-- COMPONENTE ONDE SURGE OS PRODUTOS DE CADA ENCOMENDA -->
  <div class="card">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h3>Encomenda nº {{ orderId }}</h3>
    <!-- FORMULÁRIO PARA FAZER O REGISTO DE UM PRODUTO -->
    <div class="row">
      <div class="col registerInput">
        <form v-on:submit.prevent="registeProduct()">
          <div class="form-group row">
            <label for="ean" id="eanLabel" class="col-sm-1 col-form-label col-form-label-sm">EAN:</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                autofocus
                v-model="ean"
                onclick="this.select()"
              />
            </div>
            <div class="col-sm-1" v-if="this.lastRegist != ''">
              <i>{{ lastRegist }}</i>
            </div>
            <div class="col-sm-1">
              <button type="submit" class="btn btn-primary">Registar</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="box box1">
              <img :src="img" />
            </div>
          </div>
          <div class="col infoProduct">
            <div class="form-group">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">SKU</div>
                </div>
                <input type="text" class="form-control form-control" id="sku" v-model="skuInfo" disabled />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">EAN</div>
                </div>
                <input type="text" class="form-control form-control" id="ean" v-model="eanInfo" disabled />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">Name</div>
                </div>
                <input type="text" class="form-control form-control" id="productName" disabled v-model="nameInfo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- 3 COLUNAS -->
    <b-container>
      <b-row align-v="center">
        <!-- PRIMEIRA COLUNA SURGE A DATA DA ENCOMENDA -->
        <b-col cols="2" class="left">
          <p>{{ this.order[0].date }}</p>
        </b-col>
        <!-- SEGUNDA COLUNA SURGE A QUANTIDADE DE CADA PRODUTO, O NOME, E O SKU CORRESPONDENTE -->
        <b-col cols="8">
          <span v-for="article in articles" :key="article.id">
            <p>
              {{ article.quantity }} x {{ article.sku }} -<a
                id="openArticle"
                v-b-modal.modal-4
                @click="passProductData(article)"
              >
                {{ article.name }}
              </a>
              <span
                style="color: red"
                v-if="
                  article.notes != null || article.notes != 'null' || article.notes != '' || article.notes != undefined
                "
                >- <b>{{ article.notes }}</b></span
              >
            </p></span
          ></b-col
        >
        <!-- TERCEIRA COLUNA DIZ-NOS QUAIS PROTUDOS JÁ FORAM REGISTADOS -->
        <b-col cols="2">
          <div v-if="this.order[0].status == 'completed'">
            <p class="completed">COMPLETO</p>
          </div>
          <div v-if="this.order[0].status == 'canceled'">
            <p class="completed">CANCELADA</p>
          </div>
          <div v-else-if="this.order[0].status == 'packing'">
            <span v-for="(registed, index) in produtsRegisted" :key="index">
              <p>{{ registed.registed }}/{{ registed.total }}</p>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <b-row>
      <b-col cols="10"></b-col>
      <b-col cols="2">
        <span v-if="this.arrayArticles.length == 0 || this.arrayArticles == ''">
          <button id="next" type="submit" class="btn btn-primary mb-2" @click="choiceCompany()" cols="2">
            Seguinte
          </button></span
        >
        <span v-else
          ><button type="submit" class="btn btn-primary mb-2" cols="2" disabled>Seguinte</button></span
        ></b-col
      ></b-row
    >
    <!-- MODAL PARA MOSTRAR O PRODUTO -->
    <b-modal ref="modal_product" id="modal-4" title="Produto" size="lg" hide-footer>
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p><span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}</p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">Editar Produto</button>
      </div>
    </b-modal>
    <b-modal ref="modal_address" id="modal-address" :title="'Validar Morada'" size="lg" hide-footer>
      <form>
        <div class="form-group">
          <label for="addressInput">Morada:</label>
          <input type="text" class="form-control" id="addressInput" v-model="addressUpdate" />
        </div>
        <div class="form-group">
          <label for="cityInput">Cidade:</label>
          <input type="text" class="form-control" id="cityInput" v-model="cityUpdate" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Código Postal:</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="postalCodeUpdate" />
        </div>
        <div class="form-group">
          <label for="stateInput">Estado:</label>
          <input type="text" class="form-control" id="stateInput" v-model="stateUpdate" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select class="form-control" id="exampleFormControlSelect1" v-model="countryUpdate">
            <option v-for="country in countries" :key="country.code" :value="country.code">
              {{ country.code }} ({{ country.name }})
            </option>
          </select>
          <div class="google">
            <button class="btn btn-success" @click="openGoogle()">Abrir Google Maps</button>
          </div>
          <div class="google">
            <button class="btn btn-primary" @click="approveValidationAddress()">Validar Morada</button>
            <button class="btn btn-warning" @click="sendEmailToApproveAddress()">Enviar email ao cliente</button>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- <button @click="cttExpresso()">EXPRESSO</button> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  props: {
    orderId: String,
    articles: Array,
    order: Array,
    allProducts: Array,
  },
  components: {
    Loading,
  },
  data: function () {
    return {
      products: '',
      ean: '',
      filterProducts: '',
      productsArray: [],
      produtsRegisted: [],
      expedition: '',
      loading: false,
      picking: [],
      eans: '',
      lastRegist: '',
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      arrayArticles: [],
      img: 'https://www.svgrepo.com/show/97910/image.svg',
      skuInfo: '',
      eanInfo: '',
      nameInfo: '',
    };
  },
  methods: {
    async openProdPage() {
      window.open(`https://${window.location.host}/product/${this.idProduct}`, '_blank');
      this.$refs['modal_product'].hide();
    },
    /* OBTER TODOS OS DADOS DOS PRODUTOS DAS ENCOMENDAS */
    async createArrayForArticles() {
      for (let i = 0; i < this.articles.length; i++) {
        console.log(this.articles[i].quantity);
        for (let j = 0; j < this.articles[i].quantity; j++) {
          console.log('ENTROU');
          this.arrayArticles.push(this.articles[i].sku);
        }
      }
      console.log(this.arrayArticles);
      this.addProductsToArray();
    },
    /* REGISTAR PRODUTO */
    async registeProduct() {
      console.log('REGISTO');
      if (this.ean != '') {
        /* await this.getEAN(); */
        console.log(this.eans);
        /* FILTRAR PRODUTO ATRAVÉS DO EAN */
        let findEAN = this.articles.filter((product) => product.all_ean.includes(this.ean));
        console.log(findEAN);
        /* SKU QUE VAMOS COMPARAR PARA VER SE AINDA FALTA REGISTAR */
        let sku = [];
        sku.push(this.ean);
        /* CASO O EAN EXISTA IREMOS VER A QUAL SKU CORRESPONDE E IGUALAMOS */
        if (findEAN.length > 0) {
          sku = findEAN[0].sku;
          console.log(findEAN[0].sku);
        }
        console.log('EAN: ' + sku);
        /* VAMOS COMPARAR SKU's VISTO QUE É A INFORMAÇÃO QUE RECEBEMOS QUANDO OBTEMOS OS PRODUTOS DAS ENCOMENDAS */
        let productIndex = this.arrayArticles.findIndex((product) => sku.includes(product));
        if (productIndex == -1) {
          /* SWEET - ALERT; */
          try {
            await this.$store.dispatch('searchProductsEanSku', {
              search: this.ean,
            });
            const prod_found = this.getProductsSkuEan;
            console.log('PRODUTO ENCONTRADO: ', prod_found);
            this.img = prod_found[0].img;
            this.skuInfo = prod_found[0].sku[0];
            this.eanInfo = prod_found[0].ean[0];
            this.nameInfo = prod_found[0].name;
            console.log('PRODUTO ENCONTRADO: ', prod_found);
          } catch (err) {
            alert(err);
          }
          this.$swal({
            icon: 'error',
            title: 'Produto não faz parte da encomenda',
            showConfirmButton: false,
          });
        } else {
          const filterProduct = this.articles.filter((product) =>
            product.all_sku.includes(this.arrayArticles[productIndex])
          );
          console.log('produto', filterProduct);
          console.log('VALIDADE: ', filterProduct[0].validity);
          if (filterProduct[0].validity != null) {
            const date = new Date();
            date.setMonth(date.getMonth() + 6);
            const val_6 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
            date.setMonth(date.getMonth() + 3);
            const val_9 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
            const val_date = new Date(filterProduct[0].validity);
            console.log(val_6, val_9, val_date);
            let next = false;
            if (val_6 > val_date) {
              console.log('Validade inferior a 6 meses');
              console.log(val_6, val_date);
              await this.$swal({
                title: 'O produto com SKU:' + filterProduct[0].all_sku[0] + ' tem menos de 6 meses de validade!',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                  // this.arrayArticles.splice(productIndex, 1);
                  // this.addProductsToArray();
                  next = true;
                }
              });
            } else if (val_6 < val_date && val_9 > val_date) {
              console.log('Validade inferiror a 9 meses');
              console.log(val_9, val_date);
              await this.$swal({
                title: 'O produto com SKU:' + filterProduct[0].all_sku[0] + ' tem menos de 9 meses de validade!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                  // this.arrayArticles.splice(productIndex, 1);
                  // this.addProductsToArray();
                  next = true;
                }
              });
            } else {
              // console.log("Ainda tem Validade");
              // this.arrayArticles.splice(productIndex, 1);
              // this.addProductsToArray();
              next = true;
            }
            console.log('NEXT: ', next);
            // ADICIONAR VALIDAÇÂO SE TEM NOTAS DE FINALIZAÇÃO
            if (next) {
              // PROCURAR POR INDEX DO SKU
              let ind = this.articles.findIndex((product) => product.sku == sku);
              // VERIFICAR SE O ALERTA JÁ FOI DADO
              if (this.articles[ind].show == false) {
                // VERIFICAR SE O PRODUTO TEM NOTAS DE FINALIZAÇÃO
                if (
                  filterProduct[0].note_finish != null &&
                  filterProduct[0].note_finish != 'null' &&
                  filterProduct[0].note_finish != '' &&
                  filterProduct[0].note_finish != undefined
                ) {
                  await this.$swal({
                    title:
                      'O produto com SKU:' +
                      filterProduct[0].all_sku[0] +
                      ' tem a seguinte nota: \n' +
                      filterProduct[0].note_finish,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, continuar!',
                    cancelButtonText: 'Cancelar',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.arrayArticles.splice(productIndex, 1);
                      this.addProductsToArray();
                      this.articles[ind].show = true;
                    }
                  });
                }
                // CASO NÃO TENHA ADICIONAMOS NA MESMA O REGISTO
                else {
                  this.arrayArticles.splice(productIndex, 1);
                  this.addProductsToArray();
                  this.articles[ind].show = true;
                }
              }
              // CASO JÁ TENHA SURGIDO O ALERTA REGISTAMOS NA MESMA O PRODUTO
              else {
                this.arrayArticles.splice(productIndex, 1);
                this.addProductsToArray();
                this.articles[ind].show = true;
              }
            }
          } else {
            let next = true;
            // PROCURAR POR INDEX DO SKU
            let ind = this.articles.findIndex((product) => product.sku == sku);
            // VERIFICAR SE O ALERTA JÁ FOI DADO
            if (this.articles[ind].show == false) {
              // VERIFICAR SE O PRODUTO TEM NOTAS DE FINALIZAÇÃO
              if (
                filterProduct[0].note_finish != null &&
                filterProduct[0].note_finish != 'null' &&
                filterProduct[0].note_finish != '' &&
                filterProduct[0].note_finish != undefined
              ) {
                await this.$swal({
                  title:
                    'O produto com SKU:' +
                    filterProduct[0].all_sku[0] +
                    ' tem a seguinte nota: \n' +
                    filterProduct[0].note_finish,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim, continuar!',
                  cancelButtonText: 'Cancelar',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.arrayArticles.splice(productIndex, 1);
                    this.addProductsToArray();
                    this.articles[ind].show = true;
                  } else {
                    next = false;
                  }
                });
              }
            }
            if (next) {
              this.arrayArticles.splice(productIndex, 1);
              this.addProductsToArray();
            }
          }
          this.img = filterProduct[0].img;
          this.skuInfo = filterProduct[0].all_sku[0];
          this.eanInfo = filterProduct[0].all_ean[0];
          this.nameInfo = filterProduct[0].name;
        }
      }

      this.ean = '';
    },
    // async putOrder(array, sku) {
    //   try {
    //     await this.$store.dispatch('putOrderProducts', {
    //       id: this.orderId,
    //       products: array,
    //       picking: false,
    //       orderId: this.orderId,
    //       sku: sku,
    //       ean: this.ean,
    //     });
    //     /* Armazenar último Produto Registado */
    //     this.lastRegist = this.ean;
    //     this.getProductsFromOders();
    //     /* SWEET - ALERT; */
    //     this.$swal({
    //       toast: true,
    //       position: 'top-end',
    //       icon: 'success',
    //       title: 'Produto Registado',
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   } catch (err) {
    //     /* SWEET - ALERT; */
    //     this.$swal({
    //       icon: 'error',
    //       title: 'Ooops',
    //       text: err,
    //     });
    //   }
    // },
    /* ADICIONAR OS PRODUTOS A UM ARRAY */
    addProductsToArray() {
      /* FILTRAR PRODUTOS PELO Nº DA ENCOMENDA */
      for (let i = 0; i < this.articles.length; i++) {
        /* OBTER DADOS DO PRODUTO */
        let search = this.arrayArticles.filter((product) => product == this.articles[i].sku);
        /* OBTER POSIÇÂO DO PRODUTO */
        let index = this.produtsRegisted.findIndex((product) => product.sku == this.articles[i].sku);
        /* CASO NÃO ENCONTRE ADICIONAMOS ESTE PRODUTO AO ARRAY DOS PRODUTOS REGISTADOS */
        if (index == -1) {
          this.produtsRegisted.push({
            sku: this.articles[i].sku,
            registed: parseInt(this.articles[i].quantity - search.length),
            total: this.articles[i].quantity,
          });
        } else {
          this.produtsRegisted[index].registed = parseInt(this.articles[i].quantity - search.length);
        }
        /* } */
      }
    },
    async openGoogle() {
      window.open(
        `https://www.google.com/maps/search/?api=1&basemap=satellite&query=${this.order[0].adress1.replace(
          /\s*$/,
          ''
        )}, ${this.order[0].city}, ${this.order[0].state}, ${this.order[0].postcode}, ${this.order[0].country}`,
        '_blank'
      );
    },
    /* ESCOLHER DISTRIBUIDORA */
    async choiceCompany() {
      /* this.loading = true; */
      console.log(this.order);
      /* try {
        console.log(this.order[0].id_invoice);
        if (this.order[0].id_invoice == "") {
          await this.$store.dispatch("fetchInvoices", {
            order: this.orderId,
          });
          setTimeout(this.next, 5000);
        } else { */
      this.$parent.getOrder(true);
      this.$root.$emit('shippingCompany');
      this.$parent.choiceCompany();
      /* }
      } catch (err) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      } */
    },
    async next() {
      alert('ESPEROU');
      this.$parent.getOrder(true);
      this.$root.$emit('shippingCompany');
      /* this.$parent.choiceCompany(); */
      this.loading = false;
    },
    /* VERIFICAR A DISTRIBUIDORA DO ENVIO */
    async checkOrderExpedition() {
      try {
        await this.$store.dispatch('fetchOrderExpeditionByID', {
          id: this.id,
        });
        this.expedition = this.getOrderExpeditionByID.data;
        console.log(this.expedition);
        if (this.expedition.length != 0) {
          if (this.expedition[0].shipping_company == 'nacex') {
            this.$parent.getNacexLabel(this.expedition[0].expedition_number);
          } else if (this.expedition[0].shipping_company == 'dhl') {
            console.log(this.expedition[0].tracking_number);
            console.log(this.expedition);
            this.$parent.getCallDHLLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number,
              this.expedition[0].xml
            );
          } else if (this.expedition[0].shipping_company == 'ctt expresso') {
            this.$parent.getCallCTTEXPRESSOLabel(this.expedition[0].expedition_number);
          } else if (this.expedition[0].shipping_company == 'ctt') {
            this.$parent.callCTTLabel(this.expedition[0].expedition_number, this.expedition[0].tracking_number);
          } else if (this.expedition[0].shipping_company == 'fedex') {
            this.$parent.getFedExLabbel(this.expedition[0].expedition_number, this.expedition[0].tracking_number);
          }
        }
      } catch (err) {
        alert(err);
      }
    },
    async getEAN() {
      console.log('Entrou Atualizar EAN');
      try {
        try {
          await this.$store.dispatch('fetchProductsEAN');
          this.eans = this.getProductsEAN;
          console.log('TODOS EANS: ', this.eans);
          /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        } catch (err) {
          alert(err);
        } finally {
          this.loading = false;
        }
      } catch (err) {
        alert(err);
      }
    },
    async passProductData(data) {
      // const filterProduct = this.allProducts.filter((product) => product.sku.includes(data.sku));
      this.eanProduct = data.ean;
      this.skuProduct = data.all_sku[0];
      this.hsProduct = data.hscode;
      this.warehouseProduct = data.note_warehouse;
      this.notesProduct = data.note_finish;
      this.weightProduct = data.weight;
      this.imgProduct = data.img;
      this.idProduct = data.id_backoffice;
      this.stockProduct = data.stock;
      // console.log(filterProduct, data);
    },
  },

  computed: {
    ...mapGetters(['getOrderProducts']),
    ...mapGetters(['getOrderExpeditionByID']),
    ...mapGetters(['getProductsSkuEan']),
    // ...mapGetters(['getProductsEAN']),
  },
  mounted() {
    // this.$root.$on('component1', () => {
    //   // your code goes here
    //   this.getProductsFromOders();
    // });
  },
  async created() {
    // await this.getProductsFromOders();
    await this.createArrayForArticles();
    await this.checkOrderExpedition();
    await this.allCountries();
    await this.openValidationAddress();
    // await this.getEAN();
  },
};
</script>
<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
h3 {
  color: white;
}
p {
  text-align: center;
}
.completed {
  color: green;
  font-weight: bold;
}
form {
  margin: 10px;
}
label {
  text-align: right;
  color: white;
  font-weight: bold;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
#eanLabel {
  background-color: rgb(74, 106, 192);
  border-radius: 6px;
  color: white;
}
.google {
  text-align: center;
}
.google button {
  background-color: none;
}
#modal-address label {
  background-color: white;
  color: black;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  background-color: rgb(104, 155, 75);
  border: none;
}
button:hover {
  background-color: rgba(104, 155, 75, 0.788);
  border: none;
  box-shadow: none;
}
button:focus {
  background-color: rgb(104, 155, 75);
  border: none;
  box-shadow: none;
}
i {
  text-align: center;
  color: grey;
  opacity: 0.6;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.box1 {
  border: 3px solid #ff9393;
  border-radius: 5px;
}
.infoProduct {
  margin-top: 3%;
}
.registerInput {
  margin-top: 7%;
}
</style>
