<template>
  <div class="card">
    <h3 class="title">Encomenda nº: {{ orderId }}</h3>
    <hr />
    <div class="row">
      <div class="col">
        <h4>Expedição nº:</h4>
        <h3>{{ expedition }}</h3>
      </div>
      <div class="col">
        <h4>Transportadora:</h4>
        <h3>NACEX</h3>
      </div>
      <div class="col">
        <h4>Etiqueta</h4>
        <button class="btn btn-primary mb-2" @click="getTag()">
          <h4>Etiqueta</h4>
        </button>
      </div>
      <div class="col">
        <h4>Cancelar</h4>
        <button class="btn btn-primary mb-2" @click="delExpedition()">
          <h4>Cancelar</h4>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orderId: String,
    expedition: String,
  },
  data: function () {
    return {};
  },
  methods: {
    async getTag() {
      await this.$store.dispatch("fetchTagNacex", {
        expedition: this.expedition,
      });
    },
    async delExpedition() {
      if (confirm("Quer cancelar esta expedição") == true){
        await this.$store.dispatch("deleteExpeditionNacex", {
          expedition: this.expedition,
        });
        this.$parent.productsOrderList();
        }
    },
  },
  updated() {},
  computed: {
    ...mapGetters(["getEspeditionNacex"]),
  },
};
</script>
<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
.title {
  text-align: left;
  color: white;
}
h4 {
  color: white;
}
</style>
