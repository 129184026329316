import { render, staticRenderFns } from "./NacexLabel.vue?vue&type=template&id=6d895b70&scoped=true"
import script from "./NacexLabel.vue?vue&type=script&lang=js"
export * from "./NacexLabel.vue?vue&type=script&lang=js"
import style0 from "./NacexLabel.vue?vue&type=style&index=0&id=6d895b70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d895b70",
  null
  
)

export default component.exports