<template>
  <div class="card">
    <!-- LOADING COMPONENTE -->
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h3>FedEx - {{ this.order[0].id }}</h3>
    <hr />
    <!-- FORMULÁRIO DO ENVIO DA DHL -->
    <form v-on:submit.prevent="fedexShipment()">
      <div class="row">
        <div class="col">
          <!-- NOME DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="firstName">Primerio Nome:</label>
          <input id="firstName" type="text" class="form-control" v-model="firstName" />
        </div>
        <div class="col">
          <!-- APELIDO DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="secondName">Apelido:</label>
          <input id="secondname" type="text" class="form-control" v-model="secondName" />
        </div>
        <div class="col">
          <!-- EMAIL DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="email">Email:</label>
          <input id="email" type="text" class="form-control" :placeholder="this.order[0].email" disabled />
        </div>
      </div>
      <div class="row">
        <hr />
        <a id="aUpdateAddress" @click="translate(finalAdress, city, state)">🌐</a>
        <div class="col">
          <!-- MORADA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="adress">Morada:</label>
          <input id="adress" type="text" class="form-control" v-model="finalAdress" />
        </div>
        <div class="col">
          <!-- CIDADE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="city">Cidade:</label>
          <input id="city" type="text" class="form-control" v-model="city" />
        </div>
        <div class="col">
          <!-- PAÍS DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="country">País:</label>
          <input id="country" type="text" class="form-control" :placeholder="this.order[0].country" disabled />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- EDITÁVEL -->
          <label for="postalCode">Estado:</label>
          <div class="input-group mb-2">
            <input id="postalCode" type="text" class="form-control" v-model="state" />
          </div>
        </div>
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- EDITÁVEL -->
          <label for="postalCode">Código Postal:</label>
          <div class="input-group mb-2">
            <input id="postalCode" type="text" class="form-control" v-model="postalCode" required />
          </div>
        </div>
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="total">Contacto Telefónico:</label>
          <div class="input-group mb-2">
            <input id="total" type="text" class="form-control" v-model="phone" />
          </div>
        </div>
        <div class="col">
          <!-- MOEDA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="total">Total ({{ this.order[0].coin }}):</label>
          <input id="total" type="text" class="form-control" :placeholder="this.order[0].total" disabled />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- PESO DA ENCOMENDA -- NÃO EDITÁVEL -->
          <label for="weigth">Peso (Kg):</label>
          <input id="length" type="text" class="form-control" v-model="weigthInput" />
          <a style="color: red">Peso esperado:</a> {{ this.order[0].expected_weight }} Kg
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- PEQUENA DESCRIÇÃO DO ENVIO_ex:nºencomenda -- EDITÁVEL -->
          <label for="length">Descrição:</label>
          <input id="length" type="text" class="form-control" placeholder="Ex: Nº da encomenda" v-model="description" />
        </div>
      </div>
      <!-- CONDIÇÃO PARA VERIFICAR SE O PAÍS DE ORIGEM É COMUNITÁRIO -->
      <span v-if="this.comunitary == false">
        <!-- CASO SEJA EXTRA COMUNITÁRIO SURGE FORMULÀRIO PARA COLOCAR-MOS O HS CODE  -->
        <div id="form">
          <!-- v-bind:style="{ display: form }" -->
          <hr />
          <div class="row">
            <div class="col">
              <h3 id="titleForm">Dados Alfandegários</h3>
            </div>
            <div class="col" style="text-align: end">
              <b-button class="canceled" @click="updateProducts">🔁</b-button>
            </div>
          </div>
          <div v-for="article in articles" :key="article.id">
            <div class="form-group row">
              <div class="col-sm-8">
                <label for="product">Produto:</label>
                <input id="product" type="text" class="form-control" v-model="article.name" disabled />
              </div>
              <div class="col-sm-2">
                <label for="hscode">HS Code:</label>
                <!-- CASO HAJA MODIFICAÇÃO NO INPUT AUTOMATICAMENTE FAZ O UPDATE -->
                <input id="hscode" type="text" class="form-control" v-model="article.hscode" v-on:change="updateHScode(article.id_backoffice, article.note_warehouse, article.note_finish, article.weight, article.hscode, article.country)" required />
              </div>
              <div class="col-sm-2">
                <label for="hscode">Cód. País:</label>
                <!-- CASO HAJA MODIFICAÇÃO NO INPUT AUTOMATICAMENTE FAZ O UPDATE -->
                <input id="hscode" type="text" class="form-control" v-model="article.country" v-on:change="updateHScode(article.id_backoffice, article.note_warehouse, article.note_finish, article.weight, article.hscode, article.country)" required />
              </div>
            </div>
          </div>
        </div>
      </span>
      <div class="row">
        <div class="col-sm-10"></div>
        <div class="col-sm-2">
          <button class="btn btn-primary mb-2" type="submit">ENVIAR</button>
        </div>
      </div>
    </form>
    <!-- Modal de tradução -->
    <b-modal id="modal-1" title="Tradução de morada" ref="modal_translate" hide-footer>
      <div class="form-group">
        <label for="contactInput">Morada:</label>
        <input type="text" class="form-control" id="contactInput" v-model="address_translate" />
        <b-form-checkbox v-model="accept_address" name="check-button" switch size="lg"></b-form-checkbox>
      </div>
      <div class="form-group">
        <label for="contactInput">Cidade:</label>
        <input type="text" class="form-control" id="contactInput" v-model="city_translate" />
        <b-form-checkbox v-model="accept_city" name="check-button" switch size="lg"></b-form-checkbox>
      </div>
      <div class="form-group">
        <label for="contactInput">Estado:</label>
        <input type="text" class="form-control" id="contactInput" v-model="state_translate" />
        <b-form-checkbox v-model="accept_state" name="check-button" switch size="lg"></b-form-checkbox>
      </div>
      <button type="click" class="btn btn-primary" @click="updateAddressTranslated()">Traduzir</button>
    </b-modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Loading,
  },
  props: {
    orderId: String,
    articles: Array,
    order: Array,
    comunitary: Boolean,
    weight: Number,
  },
  data: function () {
    return {
      finalAdress: this.order[0].adress1 + this.order[0].adress2,
      firstName: this.order[0].first_name,
      secondName: this.order[0].second_name,
      city: this.order[0].city,
      phone: this.order[0].phone,
      description: this.order[0].id,
      postalCode: this.order[0].postcode,
      loading: false,
      tracking: '',
      weigthInput: this.weight,
      filePdf: 'null',
      products: [],
      allProducts: [],
      commodities: [],
      currency: this.order[0].coin,
      state: this.order[0].state,
      //Translate
      address_translate: '',
      city_translate: '',
      state_translate: '',
      accept_address: true,
      accept_city: true,
      accept_state: true,
    };
  },
  methods: {
    async updateHScode(id, warehouse, finish, weight, code, country) {
      try {
        await this.$store.dispatch('putProduct', {
          id: id,
          warehouse: warehouse,
          finish: finish,
          weight: parseInt(weight),
          hscode: parseInt(code),
          country: country,
        });
        // await this.getAllProducts();
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    async fedexShipment() {
      this.loading = true;
      try {
        console.log(this.order[0].state);
        await this.$store.dispatch('createShipmentFedEx', {
          orderId: this.order[0].id,
          fullName: this.firstName + ' ' + this.secondName,
          firstName: this.firstName,
          lastName: this.secondName,
          email: this.order[0].email,
          phoneNumber: this.phone,
          address1: this.finalAdress,
          city: this.city,
          state: this.state,
          postCode: this.postalCode,
          country: this.order[0].country,
          weight: this.weigthInput,
          commodities: this.commodities,
        });
        await this.$parent.getFedExLabel(this.getFedExData.trackingID, this.getFedExData.label, this.getFedExData.trackingID);
        await this.$parent.getOrder();
      } catch (err) {
        console.log('ERRRRRRRROOOOOOOOO', err);
        if (err.code != undefined) {
          this.$swal({
            title: `${err.code}`,
            text: `${err.message}`,
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Refresh!',
          }).then(async (result) => {
            if (result.isConfirmed) {
              // location.reload();
            }
          });
        } else {
          this.$swal({
            icon: 'error',
            title: 'Ooops',
            text: err,
          });
        }
      } finally {
        this.loading = false;
      }
    },
    /* OBTER TODOS OS PRODUTOS */
    async getAllProducts() {
      console.log(this.comunitary);
      try {
        // await this.$store.dispatch('fetchProducts');
        // this.allProducts = this.getProducts.data;
        this.orderProducts();
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    async getCurrency() {
      try {
        await this.$store.dispatch('getCurrencyFedEx', {
          countryCode: this.order[0].country,
        });
        if (this.getFedExCurrency != 'none') {
          this.currency = this.getFedExCurrency;
        } else {
          console.log('None');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async orderProducts() {
      for (let i = 0; i < this.articles.length; i++) {
        console.log('Currency', this.currency);
        let product = this.articles[i];
        console.log('PRODUTO:', product.id);
        console.log('ARTICLE:', this.articles[i].product_id);

        const item = {
          description: product.name,
          countryOfManufacture: product.country,
          quantity: this.articles[i].quantity,
          quantityUnits: 'PCS',
          unitPrice: {
            amount: this.articles[i].price,
            currency: this.currency,
          },
          customsValue: {
            amount: this.articles[i].total,
            currency: this.currency,
          },
          weight: {
            units: 'KG',
            value: Number(parseFloat(product.weight / 1000).toFixed(3)),
          },
        };
        this.commodities.push(item);
        console.log(this.commodities);
      }
    },
    async updateProducts() {
      try {
        await this.$store.dispatch('updateProducts');
      } catch (err) {
        alert(err);
      }
    },

    //Translate
    async translate(address, city, state) {
      try {
        await this.$store.dispatch('translateOrderInfo', {
          address: address,
          city: city,
          state: state,
        });
        let translated = this.getTranslated;
        this.address_translate = translated.address;
        this.city_translate = translated.city;
        this.state_translate = translated.state;
        this.$refs['modal_translate'].show();
      } catch (err) {
        console.log(err);
      }
    },
    async updateAddressTranslated() {
      if (this.accept_address == true) {
        this.finalAdress = this.address_translate;
      }

      if (this.accept_city == true) {
        this.city = this.city_translate;
      }

      if (this.accept_state == true) {
        this.state = this.state_translate;
      }

      this.$refs['modal_translate'].hide();
      this.address_translate = '';
      this.city_translate = '';
      this.state_translate = '';
      this.accept_address = true;
      this.accept_city = true;
      this.accept_state = true;
    },
  },
  async created() {
    await this.getCurrency();
    await this.orderProducts();
    // await this.getAllProducts();
  },
  computed: {
    ...mapGetters(['getProducts']),
    ...mapGetters(['getFedExData']),
    ...mapGetters(['getFedExCurrency']),
    ...mapGetters(['getTranslated']),
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.row {
  margin-bottom: 15px;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
h3 {
  color: white;
}
#send {
  text-align: right;
}
.canceled {
  background-color: red;
  border-color: rgb(255, 0, 0);
}

#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
</style>
