<template>
  <div class="card">
    <h3 class="title">Encomenda nº: {{ orderId }}</h3>
    <hr />
    <div class="row">
      <div class="col">
        <h5>Transportadora:</h5>
        <h3>{{ company }}</h3>
      </div>
      <div class="col">
        <h5>Expedição nº:</h5>
        <h3>{{ expedition }}</h3>
      </div>
      <div class="col">
        <h5>Cancelar</h5>
        <p><b>Para cancelar não entregue a encomenda ao estafeta</b></p>
      </div>
      <div
        class="col"
        v-if="
          this.order[0].send_email == '' || this.order[0].send_email == null
        "
      >
        <button class="btn btn-primary mb-2" @click="sendEmail()">
          Enviar Email
        </button>
      </div>
      <div v-else class="col">
        <h5>Email enviado</h5>
      </div>
      <div class="col">
        <h5>Nota de Crédito</h5>
        <button
          class="btn btn-info"
          v-if="role == 'admin' && this.order[0].credit_note_id == null" @click="creditNoteCreation(order[0].id, order[0].id_invoice)"
        >
          Fazer nota de crédito
        </button>
        <button
          class="btn btn-primary"
          v-else-if="this.order[0].credit_note_id != null"
        >
          {{ this.order[0].credit_note_id }}
        </button>
        <span v-else><b>Não existe Nota de Crédito</b></span>
      </div>
    </div>
    <hr />
    <!-- <div class="return" v-if="this.order[0].status == 'completed'">
      <button class="btn btn-primary" @click="internReturn()">
        Devolução Interna
      </button>
    </div> -->
    <div class="return" v-if="this.order[0].status == 'completed'">
      <button class="btn btn-primary" @click="openModal()">
        Encomenda Devolvida
      </button>
    </div>
    <div v-else-if="this.order[0].status == 'returned'">
      <h5><b>Devolução</b></h5>
      <br />
      <button
        id="returnedProducts"
        class="btn btn-info"
        @click="returnedProducts()"
      >
        Ver produtos devolvidos</button
      ><br />
      <button
        class="btn btn-success"
        id="processing"
        @click="updateOrder('processing')"
      >
        Para Processamento
      </button>
      <button
        class="btn btn-primary"
        id="finish"
        @click="updateOrder('finish')"
      >
        Finalizar
      </button>
    </div>
    <div v-else-if="this.order[0].status == 'confirmed/returned'">
      <h5>Encomenda devolvida com sucesso</h5>
    </div>
    <div
      v-else-if="
        this.order[0].status == 'lost_with_insurance' ||
        this.order[0].status == 'lost_without_insurance'
      "
    >
      <h5>Quer criar nova encomenda?</h5>
      <button class="btn btn-success" id="processing" @click="duplicateOrder()">
        Sim
      </button>
      <button class="btn btn-warning" id="processing" @click="changeStatus()">
        Não
      </button>
    </div>
    <div
      id="returnComment"
      v-if="
        this.order[0].return_comment != null ||
        this.order[0].return_comment != ''
      "
    >
      <b>Comentário à devolução</b>
      <p>{{ this.order[0].return_comment }}</p>
    </div>
    <b-modal
      ref="modal_return"
      id="modal-4"
      title="Devolução "
      size="xl"
      hide-footer
    >
      <table class="table">
        <thead>
          <tr>
            <th>Quantidade</th>

            <th>SKU/CNP</th>

            <th>Nome</th>

            <th>Validade</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(article, index) of allArticles" :key="article">
            <td style="width: 10%">
              <input
                type="number"
                class="form-control mb-2"
                v-model="allArticles[index].quantity"
              />
            </td>
            <td>
              <a
                id="openArticle"
                v-b-modal.modal-3
                @click="passProductData(article)"
                >{{ article.sku }}</a
              >
            </td>
            <td>{{ article.name }}</td>
            <td>
              <date-picker
                v-model="products[index].validity"
                type="month"
                format="YYYY-MM"
                value-type="format"
                :lang="lang"
                required
              ></date-picker>
              <span id="infinity" @click="setDataInfinity(products[index])">
                Não tem validade
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <label for="">Comentários</label>
        <textarea class="form-control" rows="2" v-model="comment"></textarea>
      </div>
      <button class="btn btn-primary float-right" @click="confirmModal()">
        Confirmar
      </button>
    </b-modal>
    <b-modal
      ref="modal_returned_products"
      id="modal-5"
      title="Produtos devolvidos"
      size="lg"
      hide-footer
    >
      <div v-for="prod of allReturnedProducts" :key="prod.id">
        <p>
          <b>{{ prod.sku }}</b> - <u>{{ prod.quantity }}x</u> {{ prod.name }}
        </p>
        <hr />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orderId: String,
    expedition: String,
    company: String,
    order: Array,
    articles: Array,
  },
  data() {
    return {
      newOrder: [],
      allArticles: [],
      allProducts: [],
      products: [],
      role: "",
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
          ],
          // MMM
          monthsShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ],
          // dddd
          weekdays: [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
          ],
          // ddd
          weekdaysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          // dd
          weekdaysMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        },
      },
    };
  },
  methods: {
    async internReturn() {
      let advance;
      await this.$swal({
        title: "Quer fazer Devolução Interna?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero!",
        cancelButtonText: "Cancelar",
      }).then(async (result2) => {
        if (result2.isConfirmed) {
          advance = true;
        } else {
          advance = false;
        }
      });
      if (advance == true) {
        let newInvoice;
        await this.$swal({
          title: "Quer manter a fatura?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, quero!",
          cancelButtonText: "Não!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            newInvoice = true;
          } else {
            newInvoice = false;
          }
        });
        try {
          await this.$store.dispatch("internReturn", {
            id: this.orderId,
            newInvoice: newInvoice,
          });
          this.$router.go();
        } catch (err) {
          alert(err);
        }
      }
    },
    async openModal() {
      this.allArticles = JSON.parse(this.order[0].articles);
      console.log(this.allArticles);
      this.$refs["modal_return"].show();
    },
    async getAllProducts() {
      try {
        await this.$store.dispatch("fetchProducts");
        this.allProducts = this.getProducts.data;
        this.productsOrder();
      } catch (err) {
        alert(err);
      }
    },
    async productsOrder() {
      this.products = [];
      let validity = "";
      for (let index = 0; index < this.articles.length; index++) {
        /* console.log(this.articles.length - 1, index); */
        let product = this.allProducts.find((product) =>
          product.sku.includes(this.articles[index].sku)
        );
        if (product.validity != null) {
          validity = product.validity.substring(0, 7);
          if (validity != "2050-01") {
            validity = "";
          }
        }
        this.products.push({ sku: product.sku, validity: validity });
      }
      console.log(this.products);
    },
    async setDataInfinity(product) {
      console.log("Infinito");
      product.validity = "2050-01";
      console.log(product);
    },
    async confirmModal() {
      await this.$swal({
        title: "Confirma que esta encomenda é uma devolução?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, confirmo!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("returnedOrder", {
              id: this.orderId,
              articles: this.allArticles,
              comment: this.comment,
              validity: this.products,
            });
            console.log(this.allArticles);
            console.log("Acabouuu!");
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: `Encomenda ${this.orderId} devolvida!`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.order[0].status = "returned";
            this.$refs["modal_return"].hide();
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
    async returnedProducts() {
      const id = this.$route.params.id;
      this.$refs["modal_returned_products"].show();
      try {
        await this.$store.dispatch("returnedProducts", {
          idOrder: id,
        });
        this.allReturnedProducts = this.getReturnedProducts;
        console.log(this.allReturnedProducts);
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async updateOrder(status) {
      let title =
        "Confirma que esta encomenda irá para o estado 'Em processamento'";
      if (status == "finish") {
        title =
          "Confirma que esta encomenda irá para o estado 'Completa/Devolvida'";
      }
      await this.$swal({
        title: title,
        text: `Irá ser feita nota de crédito da encomenda ${this.orderId}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, confirmo!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("updateStatusOrder", {
              id: this.orderId,
              orderData: this.order,
              status: status,
            });
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: `Encomenda ${this.orderId} atualizada com sucesso!`,
              showConfirmButton: false,
              timer: 1500,
            });
          } catch (err) {
            alert(err);
          }
          this.order[0].status = "confirmed/returned";
        }
      });
    },
    async userRole() {
      this.role = JSON.parse(localStorage.getItem("user")).role;
    },
    async creditNoteCreation(idOrder, idInvoice){
      this.$swal({
        title: "Quer criar uma nota de crédito?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("createCreditNote", {
              idOrder: idOrder,
              idInvoice: idInvoice
            });
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Nota de crédito criada",
              showConfirmButton: false,
              timer: 1500,
            });
          } catch (err) {
            alert(err);
          }
        }
      });
    }
  },
  async created() {
    await this.getAllProducts();
    await this.userRole();
    if (this.order[0].send_email == "" || this.order[0].send_email == null)
      await this.sendEmail();
  },
  computed: {
    ...mapGetters(["getProducts"]),
    ...mapGetters(["getInvoiceNumber"]),
    ...mapGetters(["getInvoiceDate"]),
    ...mapGetters(["getTracking"]),
    ...mapGetters(["getReturnedProducts"]),
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
.title {
  text-align: left;
  color: white;
}
h5 {
  color: white;
}
label {
  text-align: right;
}
#titleForm {
  text-align: left;
  color: white;
}
#send {
  text-align: right;
}
.form-group {
  margin: 20px;
}
a h3 {
  text-decoration: underline;
}
a h3:hover {
  text-decoration: none;
  cursor: pointer;
}
</style>
