<template>
  <!-- COMPONENTE PARA A ESCOLHA DA TRANSPORTADORA -->
  <div class="card">
    <!-- NÚMERO DA ENCOMENDA -->
    <h3>Encomenda nº {{ orderId }}</h3>
    <hr />
    <b-row align-v="center">
      <!-- TEMOS 3 COLUNAS -->
      <b-col cols="4"></b-col>
      <!-- COLUNA DO MEIO  -->
      <b-col cols="4">
        <form>
          <!-- select PARA ESCOLHER TRANSPORTADORA -->
          <select
            class="form-select"
            name="company"
            id="company"
            v-model="company"
            @change="onChangeCompany()"
            :disabled="disable == true"
          >
            <!-- OPÇÕES DE TRANSPORTADORA -->
            <option disabled value="">Escolha uma Transportadora</option>
            <option v-for="company in allCompany" :key="company.id" :value="company.company.toLowerCase()">
              {{ company.company }}
            </option>
            <option value="other">Outra Transportadora</option>
            <option value="free">Envio Gratuito</option>
          </select>
        </form>
        <!-- IMAGEM DA TRANSPORTADORA -->
        <img v-if="this.company != 'free'" v-bind:src="require(`@/assets/${img}`)" alt="" />
      </b-col>
    </b-row>
    <hr />
    <div v-if="this.company == 'free'" style="margin-left: auto; margin-right: auto">
      <table style="text-align: center; border-spacing: 10px; border-collapse: separate">
        <tr>
          <th>Peso (Kg)</th>
          <th style="background-color: red; color: white">Altura (cm)</th>
          <th>Largura (cm)</th>
          <th>Comprimento (cm)</th>
        </tr>
        <tr>
          <td>
            <input id="length" type="text" class="form-control" v-model="weight" @change="calculate(true)" />
          </td>
          <td>
            <input
              id="height"
              type="number"
              class="form-control"
              v-model="height"
              required
              :disabled="show_vol == false"
              @change="calculate()"
            />
          </td>
          <td>
            <input
              id="length"
              type="number"
              class="form-control"
              v-model="length1"
              required
              :disabled="show_vol == false"
              @change="calculate()"
            />
          </td>
          <td>
            <input
              id="length"
              type="number"
              class="form-control"
              v-model="width"
              required
              :disabled="show_vol == false"
              @change="calculate()"
            />
          </td>
        </tr>
      </table>
      <a style="color: red">Peso esperado:</a> {{ this.order[0].expected_weight }} Kg
      <span v-if="!skip"
        ><button type="submit" class="btn btn-primary mb-2" @click="calculateShipping()">Calcular</button></span
      >
      <span v-if="skip"
        ><button type="submit" class="btn btn-danger mb-2" @click="calculateShipping(true)">Forçar</button></span
      >
    </div>
    <hr />
    <b-row>
      <b-col cols="9"></b-col>
      <b-col cols="3">
        <!-- BUTÕES DE ANTERIOR E SEGUINTE -->
        <span
          ><button id="after" type="submit" class="btn mb-2" @click="productsOrderList()" cols="2">
            Anterior
          </button></span
        >
        <span
          ><button type="submit" class="btn btn-primary mb-2" cols="2" @click="next()">Seguinte</button></span
        ></b-col
      ></b-row
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  /* PROPRIEDADES QUE RECEBEMOS DO COMPONENTE PAI */
  props: {
    orderId: String,
    order: Array,
    shippingCompany: String,
    shippingTranslate: String,
    totalPrice: Number,
    shipping_selection: String,
    cs_choose: Boolean,
    price_eur: Number,
  },
  /* VARIÁVEIS */
  data: function () {
    return {
      allCompany: [],
      company: '',
      img: '',
      disable: false,
      country: this.order[0].country,
      weight: '',
      height: '',
      width: '',
      length1: '',
      result: [],
      show_vol: false,
      skip: false,
    };
  },
  methods: {
    async calculate(peso = false) {
      console.log('ENTREI');
      if (peso) {
        this.show_vol = false;
      }
      if (this.show_vol) {
        if (this.height != '' && this.width != '' && this.length1 != '') {
          await this.calculateShipping();
        }
      } else {
        await this.calculateShipping();
      }
    },
    async getCompany() {
      try {
        await this.$store.dispatch('fetchShippingCompany');
        this.allCompany = this.getShippingCompany.data;
        if (this.order[0].country == 'PT' || this.order[0].country == 'ES') {
          this.allCompany = this.getShippingCompany.data;
        } else {
          let index = this.allCompany.findIndex((company) => company.company == 'Nacex');
          this.allCompany.splice(index, 1);
        }
        console.log(this.allCompany);
        this.knowShippingCompany();
      } catch (err) {
        alert(err);
      }
    },
    /* FUNÇÃO PARA VOLTAR À LISAT DA ENCOMENDA */
    productsOrderList() {
      /* ESTA FUNÇÃO ENCONTRASSE NO COMPONENTE PAI */
      this.$root.$emit('component1');
      this.$parent.productsOrderList();
    },
    /* CASO O COMPRADOR TENHA ESCOLHIDO JÁ A TRANSPORTADORA NÃO SERÁ POSSÍVEL ALTERA-LA SENDO ASSIM TEMOS QUE VERIFICAR SE JÁ FOI ESCOLHIDA OU NÃO */
    async knowShippingCompany() {
      this.shippingCompany = this.shippingCompany.toLowerCase();
      if (this.shipping_selection == 'Sem pré-seleção') {
        if (this.shippingTranslate == 'FedEx') {
          this.disable = false;
          this.company = 'fedex';
          this.img = 'fedex1.png';
        } else if (this.shippingCompany.includes('nacex')) {
          this.disable = false;
          this.company = 'nacex';
          this.img = 'nacex.png';
        } else if (this.shippingTranslate == 'DHL') {
          this.disable = false;
          this.company = 'dhl';
          this.img = 'dhl.png';
        } else if (this.shippingTranslate == 'Free Shipping') {
          this.disable = false;
          this.company = 'free';
          this.img = 'shiping.png';
        } else if (this.shippingTranslate == 'CTT') {
          this.disable = false;
          this.company = 'ctt';
          this.img = 'ctt.png';
        } else if (this.shippingTranslate == 'CTT Azul') {
          this.disable = false;
          this.company = 'ctt azul';
          this.img = 'ctt_azul.png';
        } else if (this.shippingTranslate == 'CTT Expresso') {
          this.disable = false;
          this.company = 'ctt expresso';
          this.img = 'ctt-expresso.png';
        } else {
          this.disable = false;
          this.company = '';
          this.img = 'shiping.png';
        }
      } else {
        if (this.shipping_selection == 'FedEx') {
          this.disable = this.cs_choose;
          this.company = 'fedex';
          this.img = 'fedex1.png';
        } else if (this.shipping_selection == 'DHL') {
          this.disable = this.cs_choose;
          this.company = 'dhl';
          this.img = 'dhl.png';
        } else if (this.shipping_selection == 'CTT' || this.shipping_selection == 'CTT Registado') {
          this.disable = this.cs_choose;
          this.company = 'ctt';
          this.img = 'ctt.png';
        } else if (this.shipping_selection == 'CTT Azul') {
          this.disable = this.cs_choose;
          this.company = 'ctt azul';
          this.img = 'ctt_azul.png';
        } else if (this.shipping_selection == 'CTT Expresso') {
          this.disable = this.cs_choose;
          this.company = 'ctt expresso';
          this.img = 'ctt-expresso.png';
        } else {
          this.disable = false;
          this.company = '';
          this.img = 'shiping.png';
        }
      }
    },
    /* QUANDO ALTERAMOS A TRASNPORTADORA A IMAGEM ALTERA */
    onChangeCompany() {
      if (this.company == 'fedex') {
        this.img = 'fedex1.png';
      } else if (this.company == 'nacex') {
        this.img = 'nacex.png';
      } else if (this.company == 'dhl') {
        this.img = 'dhl.png';
      } else if (this.company == 'ctt') {
        this.img = 'ctt.png';
      } else if (this.company == 'ctt azul') {
        this.img = 'ctt_azul.png';
      } else if (this.company == 'ctt expresso') {
        this.img = 'ctt-expresso.png';
      }
    },
    async next() {
      console.log(this.weight, this.height, this.width, this.length1);
      const data = {
        weight: this.weight,
        height: this.height,
        width: this.width,
        length1: this.length1,
      };
      await this.$parent.updateShipping(data);
      console.log(this.company);
      if (this.company == 'nacex') {
        this.$parent.callNacex();
      } else if (this.company == 'dhl') {
        this.$parent.callDHL();
      } else if (this.company == 'ctt') {
        console.log('CTT');
        this.$parent.callCTT(false);
      } else if (this.company == 'ctt azul') {
        console.log('CTT Azul');
        this.$parent.callCTT(true);
      } else if (this.company == 'ctt expresso') {
        console.log('CTTExpresso');
        this.$parent.callCTTExpresso();
      } else if (this.company == 'fedex') {
        console.log('Fedex');
        this.$parent.callFedEx();
      } else if (this.company == 'other') {
        console.log('Outra Transportadora');
        this.$parent.callNoShippingCompany();
      }
    },
    async calculateShipping(skip = undefined) {
      if (this.show_vol) {
        await this.$store.dispatch('calculateShipping', {
          order_id: this.orderId,
          country: this.country,
          weight: this.weight,
          price: this.price_eur,
          height: this.height,
          width: this.width,
          length1: this.length1,
          skip: skip,
        });
      } else {
        await this.$store.dispatch('calculateShipping', {
          order_id: this.orderId,
          country: this.country,
          weight: this.weight,
          price: this.price_eur,
          skip: skip,
        });
      }
      this.result = this.getCalculatedShipping;
      this.message = this.getCalculatedShipMessage;
      console.log(this.result, this.message);
      if (this.message == undefined) {
        if ((this.result.shipping == 'DHL' || this.result.shipping == 'CTT Expresso') && this.result.measurements) {
          this.shippingTranslate = this.result.shipping;
          await this.knowShippingCompany();
        } else if (
          (this.result.shipping == 'DHL' || this.result.shipping == 'CTT Expresso') &&
          !this.result.measurements
        ) {
          this.show_vol = true;
          this.skip = false;
        } else {
          this.shippingTranslate = this.result.shipping;
          await this.knowShippingCompany();
        }
      } else {
        this.$swal({
          title: this.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Vou tentar!',
        });
        this.skip = true;
      }
      console.log(this.result);
    },
    //TODO MISSING WHEN THEY CANT CHANGE THE VOLUMETRIC WEIGHT OR THE WEIGHT
  },
  computed: {
    ...mapGetters(['getShippingCompany']),
    ...mapGetters(['getCalculatedShipping']),
    ...mapGetters(['getCalculatedShipMessage']),
  },
  mounted() {
    this.$root.$on('shippingCompany', () => {
      // your code goes here
      this.getCompany();
    });
  },
  created() {
    this.getCompany();
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}

#after {
  background-color: transparent;
  border-color: transparent;
  color: white;
}
#after:onclick {
  outline: transparent;
}
img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
h3 {
  color: white;
}
.row {
  margin-bottom: 15px;
}
</style>
