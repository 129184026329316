<template>
  <div class="card">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h3 class="title">Encomenda nº: {{ orderId }}</h3>
    <hr />
    <span v-if="this.order[0].id_invoice == '' && this.responseInvoice == ''">
      <button
        id="createInvoice"
        type="button"
        class="btn btn-primary"
        @click="createInvoice(orderId)"
      >
        Criar fatura
      </button></span
    ><span v-else-if="this.order[0].id_invoice != ''"
      ><h5>Fatura Criada com ID {{ this.order[0].id_invoice }}</h5></span
    >
    <span v-else
      ><h5>{{ responseInvoice }}</h5></span
    >

    <form v-on:submit.prevent="sendEmail()">
      <p>Escolher Transportadora:</p>
      <select class="form-select" v-model="selected" required>
        <option value="">Escolha transportadora</option>
        <option value="dhl">DHL</option>
        <option value="nacex">Nacex</option>
        <option value="ctt">CTT</option>
        <option value="fedex">FEDEX</option>
        <option value="correos">Correos Express</option>
      </select>
      <p>Número do Tracking:</p>
      <input class="form-control" type="text" v-model="tracking" required />
      <button id="sendEmail" type="submit" class="btn btn-info">
        Enviar Email
      </button>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  props: {
    orderId: String,
    order: Array,
  },
  data: function () {
    return {
      selected: "",
      tracking: "",
      responseInvoice: "",
      loading: false
    };
  },
  methods: {
    async createInvoice(order) {
      this.loading = true;
      try {
        await this.$store.dispatch("fetchInvoices", {
          order: order,
        });
        this.responseInvoice =
          "Fatura criada com o ID" + this.getMoloniInvoiceId;
        this.order[0].id_invoice = this.getMoloniInvoiceId;
      } catch (err) {
        console.log(err);
        this.$swal({
          icon: "error",
          title: err,
          showConfirmButton: true,
        });
      }
      this.loading = false;
    },
    async sendEmail() {
      this.loading = true;
      console.log(this.selected);
      try {
        await this.$store.dispatch("fetchEmail", {
          order: this.order[0],
          company: this.selected,
          trackingId: this.tracking,
        });
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Email enviado!!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$parent.callNoExpedition(this.tracking, this.selected);
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getMoloniInvoiceId"]),
  },
};
</script>
<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
select {
  margin-bottom: 10px;
}
p {
  margin-top: 20px;
}
h3 {
  text-align: left;
  color: white;
}
#sendEmail {
  margin-top: 20px;
}
#createInvoice {
  margin-bottom: 5px;
}
</style>
