<template>
  <div class="card">
    <h3>NACEX</h3>
    <hr />
    <form v-on:submit.prevent="generateLink()">
      <div class="row">
        <div class="col">
          <label for="firstName">Primerio Nome:</label>
          <input id="firstName" type="text" class="form-control" :placeholder="this.order[0].first_name" disabled />
        </div>
        <div class="col">
          <label for="secondName">Apelido:</label>
          <input id="secondname" type="text" class="form-control" :placeholder="this.order[0].second_name" disabled />
        </div>
        <div class="col">
          <label for="email">Email:</label>
          <input id="email" type="text" class="form-control" :placeholder="this.order[0].email" disabled />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="adress">Morada:</label>
          <input id="adress" type="text" class="form-control" :placeholder="this.order[0].adress1 + this.order[0].adress2" disabled />
        </div>
        <div class="col">
          <label for="city">Cidade:</label>
          <input id="city" type="text" class="form-control" :placeholder="this.order[0].city" disabled />
        </div>
        <div class="col">
          <label for="country">País:</label>
          <input id="country" type="text" class="form-control" :placeholder="this.order[0].country" disabled />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="total">Contacto Telefónico:</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend" v-if="this.order[0].country == 'PT'">
              <div class="input-group-text">+351</div>
            </div>
            <input id="total" type="text" class="form-control" v-model="phone" />
          </div>
        </div>
        <div class="col">
          <label for="total">Total ({{ this.order[0].coin }}):</label>
          <input id="total" type="text" class="form-control" :placeholder="this.order[0].total" disabled />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <select class="form-select" v-model="service">
            <option disabled value="">Tipo de Serviço *</option>
            <option value="01">NACEX 10:00H</option>
            <option value="02">NACEX 12:00H</option>
            <option value="08">NACEX 19:00H</option>
          </select>
        </div>
        <div class="col">
          <select class="form-select" v-model="env" required>
            <option disabled value="">Tipo de Envolcro*</option>
            <option value="1">Envelope Nacex</option>
            <option value="2">Caixa de cartão</option>
          </select>
        </div>
        <div class="col">
          <input type="text" class="form-control" v-model="this.weigth" :disabled="this.weigth != 0" />
        </div>
      </div>
      <div class="row"></div>
      <div class="row">
        <div class="col-sm-10"></div>
        <div class="col-sm-2">
          <button class="btn btn-primary mb-2" type="submit">ENVIAR</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    orderId: String,
    articles: Array,
    order: Array,
  },
  data: function () {
    return {
      weigth: 0,
      products: [],
      product: [],
      service: '',
      link: '',
      env: '',
      fullName: '',
      total: '',
      obs: '',
      finalAdress: this.order[0].adress1 + this.order[0].adress2,
      phone: this.order[0].phone,
      expedition: [],
      tag: '',
    };
  },
  methods: {
    async nacex() {
      await this.$store.dispatch('fetchExpeditionNacex');
    },
    async getAllProducts() {
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
        console.log(this.products);
        this.getPeso();
      } catch (err) {
        alert(err);
      }
    },
    async getPeso() {
      for (let i = 0; i < this.articles.length; i++) {
        console.log(this.articles[i].product_id);
        // this.product = this.products.find((product) => product.sku.includes(this.articles[i].sku));
        console.log(this.product);
        console.log(this.articles[i].weight);
        this.weigth = this.weigth + this.product.weight * parseInt(this.articles[i].quantity);
      }
      this.weigth = this.weigth + this.weigth * 0.1;
      this.weigth = parseFloat(this.weigth / 1000).toFixed(3);
      console.log(this.weigth);
    },
    async generateLink() {
      let done = false;
      console.log(encodeURI(this.finalAdress).length);
      if (encodeURI(this.finalAdress).length < 60) {
        if (this.order[0].country == 'PT' && this.phone.length > 9) {
          alert('ContactoInválido');
        } else if (this.order[0].country == 'PT') {
          this.pho = '351' + this.phone;
          this.fullName = this.order[0].first_name + ' ' + this.order[0].second_name;
          this.link = `tip_ser=${this.service}|tip_cob=O|ref-cli=reftest|tip_env=${this.env}|bul=1|kil=${this.weigth}|nom_ent=${encodeURI(this.fullName)}|dir_ent=${encodeURI(this.finalAdress)}|pais_ent=${encodeURI(
            this.order[0].country
          )}|cp_ent=${encodeURI(this.order[0].postcode)}|pob_ent=${encodeURI(this.order[0].city)}|tel_ent=${encodeURI(this.phone)}`;
          done = true;
        }
        if (this.obs != '') {
          this.link = this.link + `|obs1=${encodeURI(this.obs)}`;
        }
      } else {
        let lastIndex = this.finalAdress.lastIndexOf(' ');
        this.obs = this.finalAdress.substring(lastIndex + 1) + this.obs;
        this.finalAdress = this.finalAdress.substring(0, lastIndex);
        this.generateLink();
      }
      if (done) {
        await this.$store.dispatch('createExpedition', {
          data: this.link,
          orderId: this.orderId,
        });
        this.expedition = this.getEspeditionNacex;
        let index = String(this.expedition).indexOf('|');
        let expeditionNumber = String(this.expedition).substring(0, index);
        this.$parent.getNacexLabel(expeditionNumber);
      }
    },
  },
  computed: {
    ...mapGetters(['getProducts']),
    ...mapGetters(['getCurrentTotal']),
    ...mapGetters(['getEspeditionNacex']),
    ...mapGetters(['getTagNacex']),
  },
  created() {
    // this.getAllProducts();
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.row {
  margin-bottom: 15px;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
h3 {
  color: white;
}
</style>
